import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { BrowserRouter } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import { NotificationProvider } from './hooks/notifications';
import '@amzn/awsui-global-styles/polaris.css';

import { MbmProvider } from "@amzn/react-arb-tools"; 
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DEFAULT_USER_LOCALE } from './constants';
const arbManifest = require('../build/i18n/arbManifest');

/**
 * Amplify configuration
 */

const callbackURL = window.location.origin;
const config = {
    oauth: {
        domain: 'gso-greenlight-gamma.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: callbackURL,
        redirectSignOut: callbackURL,
        responseType: 'code',
    },
    // Cognito options
    aws_cognito_identity_pool_id: 'us-east-1:1bb7724c-e7e7-4353-b5c4-a72d37ee4c06',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_LgWWWGFv8',
    aws_user_pools_web_client_id: 'nmuimjs2vt49at159cqtrsesm',
    // AppSync
    aws_appsync_graphqlEndpoint: 'https://sy5a2p3bwzffdoutt6fcqc5qoy.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    API: {
        graphql_endpoint: 'https://sy5a2p3bwzffdoutt6fcqc5qoy.appsync-api.us-east-1.amazonaws.com/graphql',
        graphql_headers: async () => ({
            'Authorization': (await Auth.currentAuthenticatedUser()).getSignInUserSession().getIdToken().getJwtToken(),
            'AuthUser': (await Auth.currentAuthenticatedUser()).getUsername()
        })
    }
};

Amplify.configure(config);

/**
 * Panther / locale setup
 */

const localizationContextProvider = new LocalizationContextBuilder()
    .withDefaultLocale(DEFAULT_USER_LOCALE);

const mbm = {
    arbManifest,
    defaultLocalizationContext: localizationContextProvider.withLocale(localStorage.getItem('PREFERENCE_LANGUAGE') ?? DEFAULT_USER_LOCALE).withDefaultLocale(DEFAULT_USER_LOCALE).build(),
    urlPrefix: '/i18n',
}

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <AppInitWrapper>
            <NotificationProvider>
                <React.Suspense fallback={<Spinner size='large' />}>
                    <BrowserRouter>
                        <MbmProvider {...mbm}>
                            <App />
                        </MbmProvider>
                    </BrowserRouter>
                </React.Suspense>
            </NotificationProvider>
        </AppInitWrapper>
    </React.StrictMode>
);